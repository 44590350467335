import { Flex, Text, Heading, VStack, Image } from "@chakra-ui/react";
import React from "react";
import Flower from "../SVG/Flower";

function Buffet(props) {
  return (
    <Flex
      color="secondary"
      justify="center"
      zIndex={1}
      position="relative"
      overflow="hidden"
    >
      <Flex
        position="absolute"
        top="5rem"
        left={{ base: "-10rem", md: "-1rem", lg: "0rem" }}
        zIndex={2}
      >
        <Image src="/cloud.png" alt="Cloud" width={227} height={649} />
      </Flex>

      <Flex
        position="absolute"
        bottom="5rem"
        right={{ base: "-10rem", md: "-1rem", lg: "0rem" }}
        transform="scaleY(-1)"
      >
        <Image src="/cloud.png" alt="Cloud" width={227} height={649} />
      </Flex>
      <Flex
        mt="4.5rem"
        mb="4.5rem"
        width={{ base: "100%" }}
        direction="column"
        align="center"
        color="primary"
        fontFamily="innerbody"
        zIndex={2}
        position="relative"
        ml="5px"
        mr="5px"
      >
        <Flex borderBottomWidth="2px" borderColor="primary" position="relative">
          <Heading
            as="h1"
            textAlign="center"
            fontSize={{ base: "30px", md: "44px" }}
            fontWeight={400}
            letterSpacing={1}
            mb="1rem"
            fontFamily="innerbody"
          >
            BUFFET DETAILS
          </Heading>
          <Flex
            position="absolute"
            left="0"
            right="0"
            bottom="-15px"
            justify="center"
          >
            <Flower
              padding={"9px"}
              background={"background"}
              color={"black"}
            ></Flower>
          </Flex>
        </Flex>

        <Flex textAlign="center" direction="column" mt="5rem">
          <Text fontWeight={700} fontSize={{ base: "24px", md: "32px" }}>
            LUNCH BUFFET
          </Text>
          <Text fontSize={{ base: "16px", md: "18px" }}>11:00AM - 2:00PM</Text>

          <VStack mt="2.5rem">
            <Text fontSize={{ base: "16px", md: "18px" }} color="#3d4f60">
              Monday - Friday
            </Text>
            <Text fontSize={{ base: "24px", md: "32px" }} mt={0}>
              $18.99
            </Text>
          </VStack>

          <VStack mt="2.5rem">
            <Text fontSize={{ base: "16px", md: "18px" }} color="#3d4f60">
              Saturday - Sunday
            </Text>
            <Text fontSize={{ base: "24px", md: "32px" }} mt={0}>
              Closed
            </Text>
          </VStack>
        </Flex>
        <Flex textAlign="center" direction="column" mt="4rem">
          <Text fontWeight={700} fontSize={{ base: "24px", md: "32px" }}>
            DINNER BUFFET
          </Text>
          <Text fontSize={{ base: "16px", md: "18px" }}>5:00PM - 8:00PM</Text>

          <VStack mt="2.5rem">
            <Text fontSize={{ base: "16px", md: "18px" }} color="#3d4f60">
              Monday - Thursday
            </Text>
            <Text fontSize={{ base: "24px", md: "32px" }} mt={0}>
              $23.99
            </Text>
          </VStack>

          <VStack mt="2.5rem">
            <Text fontSize={{ base: "16px", md: "18px" }} color="#3d4f60">
              Friday - Sunday
            </Text>
            <Text fontSize={{ base: "24px", md: "32px" }} mt={0}>
              $27.99
            </Text>
          </VStack>

          <VStack mt="2.5rem">
            <Text fontSize={{ base: "16px", md: "18px" }} color="#3d4f60">
              Senior Sunday (65+)
            </Text>
            <Text fontSize={{ base: "24px", md: "32px" }} mt={0}>
              $23.99
            </Text>
          </VStack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Buffet;
