import {
  Button,
  Link,
  Flex,
  Text,
  VStack,
  Image,
  Heading,
} from "@chakra-ui/react";
import React from "react";

function Landing(props) {
  return (
    <Flex
      height="100vh"
      align="center"
      justify="center"
      color="secondary"
      width="100%"
      position="relative"
    >
      <Flex
        fontFamily="chinese"
        color="accent"
        position="absolute"
        top="150px"
        left="10px"
        direction="column"
        fontSize="5rem"
        opacity="50%"
        zIndex={1}
        overflow="none"
        whiteSpace="nowrap"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Text letterSpacing="2rem">桂裹江井</Text>
        <Text letterSpacing="2rem">花胡米水</Text>
        <Text letterSpacing="2rem">香桃如淘</Text>
        <Text letterSpacing="2rem">馅&emsp;珠 </Text>
      </Flex>

      <Flex direction="column" align="center" mb="5rem" zIndex={1}>
        <VStack
          fontFamily="heading"
          fontSize={{ base: "30px", md: "44px" }}
          letterSpacing={2}
        >
          <Heading
            fontFamily="heading"
            as="h1"
            fontWeight={400}
            mb="1rem"
            lineHeight={1}
          >
            ALL YOU CAN EAT
          </Heading>
          <Heading
            fontFamily="heading"
            as="h1"
            fontWeight={400}
            mb="1rem"
            lineHeight={1}
          >
            CHINESE BUFFET
          </Heading>
        </VStack>
        <Button
          as={Link}
          href="/menu.pdf"
          isExternal
          mt="2rem"
          fontFamily="body"
          fontWeight={700}
          bg="accent"
          color="primary"
          borderRadius={0}
        >
          VIEW MENU
        </Button>
      </Flex>

      <Flex
        position="absolute"
        bottom="5rem"
        right="0px"
        display={{ base: "none", md: "flex" }}
      >
        <Image src="/dragon.png" alt="dragon" width={329} height={557}></Image>
      </Flex>
    </Flex>
  );
}

export default Landing;
