import React from "react";
import { Flex, Text, SimpleGrid } from "@chakra-ui/react";
function Extra(props) {
  return (
    <SimpleGrid
      fontFamily="innerbody"
      fontSize={{ base: "24px", md: "32px" }}
      columns={{ base: 1, md: 2 }}
    >
      <Flex
        flex={1}
        justify="center"
        align="center"
        borderRightWidth={{ base: "0px", md: "1px" }}
        borderColor="primary"
        borderBottom={{ base: "1px", md: "0px" }}
        pt="3rem"
        pb="3rem"
      >
        <Text textAlign="center">Delivery service from Friday - Sunday</Text>
      </Flex>
      <Flex flex={1} align="center" justify="center" pt="3rem" pb="3rem">
        <Text textAlign="center">Call Us for Reservations</Text>
      </Flex>
    </SimpleGrid>
  );
}

export default Extra;
